/* General Page Styles */
.events-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Top Navigation Bar */
.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0E3364;
    padding: 10px 20px;
    color: #FFF1DB;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.logo-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.login-button {
    background-color: #FFF1DB;
    color: #0E3364;
    border: none;
    border-radius: 50px;
    padding: 10px 15px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 12px */
    letter-spacing: -0.2px;
}

/* Main Section */
.main-section {
    background-color: #FFF1DB;
    padding: 50px 20px;
    text-align: center;
    color: #0E3364;
    height: 78vh;
}

.heading {
    font-size: 2rem;
    color: #0E3364;
    margin-bottom: 30px;
}

/* Registration Form Styles */
.registration-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin: 2rem auto;
    max-width: 1000px;
}

.section {
    flex: 1;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.section h2 {
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.add-button {
    background-color: #0044cc;
    color: white;
    border: none;
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.875rem;
    cursor: pointer;
}

.add-button:hover {
    background-color: #003399;
}

.form-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.form-input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

input[type="checkbox"] {
    margin-right: 0.5rem;
}

label {
    font-size: 0.875rem;
    color: #555;
}

/* Submit Registration Section */
.submit-registration-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.submit-registration-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #0E3364;
}

.terms-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.terms-section input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 4px;
}

.terms-section label {
    font-size: 0.875rem;
    color: #555;
    line-height: 1.5;
}

.next-button {
    background-color: #0044cc;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    max-width: 200px;
    height: 50px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.next-button:hover {
    background-color: #003399;
}

/* Navigation buttons */
.navigation-buttons {
    margin-top: 2rem;
    text-align: center;
}

.next-button,
.previous-button,
.skip-button {
    background-color: #0044cc;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 1rem;
    font-size: 1rem;
    width: 200px;
    height: 60px;
}

.skip-button {
    background-color: #ff6f61;
}

.previous-button:hover {
    background-color: #003399;
}