body {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    background-color: #0E3364;
    padding-top: 10px;
}

.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0E3364;
    padding: 10px 20px;
    color: #FFF1DB;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #FFF1DB;
    padding-top: 10px;
    padding-bottom: 10px;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.logo-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.home-button {
    background-color: #FFF1DB;
    color: #0E3364;
    border: none;
    border-radius: 50px;
    padding: 10px 15px;
    width: 140px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 12px */
    letter-spacing: -0.2px;
}

.logo-box {
    background-color: #0E3364;
    padding: 30px 40px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.login-box {
    background-color: #FFF1DB;
    padding: 30px 40px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.login-logo {
    width: 80px;
}

h2 {
    color: #0E3364;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

.input-field {
    margin: 8px 16px;
    border: 2px solid #DCC6A4;
    border-radius: 7px;
    font-size: 16px;
    width: 74%;
    padding-right: 60px;
    padding-left: 10px;
    height: 50px;
    align-items: center;
}

.input-field::placeholder {
    opacity: 25%;
}

.password-container {
    position: relative;
    width: 100%;
}

.show-password {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #0E3364;
    font-size: 0.9rem;
    cursor: pointer;
    outline: none;
}

.forgot-password {
    color: #1f3b77;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 15px;
}

.signin-button {
    background-color: #0E3364;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    height: 55px;
    width: 95%;
    align-self: center;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 15px 0;
}

.google-signin,
.microsoft-signin,
.signup-button {
    background-color: #0E3364;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    height: 55px;
    width: 95%;
    align-self: center;
    margin-bottom: 16px;
}

.google-icon,
.microsoft-icon {
    font-size: 18px;
    margin-right: 10px;
}

.signup-button {
    background-color: #fff;
    color: #0E3364;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer-section {
    background-color: #0E3364;
    color: #FFF1DB;
    text-align: center;
    padding: 1px;
    height: 15;
}

.footer-divider {
    border: 0;
    height: 1px;
    background: #FFF1DB;
    margin: 10px 0;
}
