.app {
    font-family: Arial, sans-serif;
    color: #333;
}

.header {
    background: #00264d;
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 1.5em;
}

.category-section {
    margin: 20px;
}

.category-section h2 {
    margin: 10px 0;
    font-size: 1.2em;
}

.event-card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.event-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.event-details h3 {
    margin: 10px 0;
    font-size: 1em;
}