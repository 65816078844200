.events-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0E3364;
    padding: 10px 20px;
    color: #FFF1DB;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.logo-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.section {
    flex: 1;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.login-button {
    background-color: #FFF1DB;
    color: #0E3364;
    border: none;
    border-radius: 50px;
    padding: 10px 15px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 12px */
    letter-spacing: -0.2px;
}

.main-section {
    background-color: #FFF1DB;
    padding: 50px 20px;
    text-align: center;
    color: #0E3364;
    height: 78vh;
}

.registration-container {
    display: flex;
    justify-content: space-between;
    margin: 2rem auto;
    max-width: 800px;
}

.personal-info-form {
    flex: 2;
    display: grid;
    gap: 1rem;
}

.form-group {
    display: flex;
    gap: 1rem;
}

.form-input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

/* Profile photo upload */
.profile-photo-upload {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 1rem;
    background-color: #f9f9f9;
}

.upload-box {
    text-align: center;
}

.upload-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: none;
    background-color: #ff6f61;
    color: white;
    font-size: 1.5rem;
    line-height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 1rem;
}

.upload-button:hover {
    background-color: #e65c50;
}

.navigation-buttons {
    margin-top: 2rem;
}

.next-button,
.skip-button {
    background-color: #0044cc;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 1rem;
    font-size: 1rem;
}

.skip-button {
    background-color: #ff6f61;
}

.next-button:hover {
    background-color: #003399;
}

.skip-button:hover {
    background-color: #e65c50;
}